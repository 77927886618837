import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import QuizSchema from '../../schema/QuizSchema';
import CourseApis from '../../queries/course';
import toast from 'react-hot-toast'
// import { TimePicker } from 'antd';
import dayjs from 'dayjs';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom'

function AddQuizModal({ show, handleClose, getAllmodules, courseId, moduleId }) {
    const { register, trigger, setError, formState: { errors }, handleSubmit, reset, control, watch } = useForm({
        resolver: yupResolver(QuizSchema),
    });
    const formValues = watch()
    const modalClose = () => {
        handleClose()
        reset()
    }

    const Addquiz = (data, e) => {
        e.preventDefault()
        navigate(`/add-questions/${moduleId}`, { state: { data } })
    }
    const navigate = useNavigate()
    return (
        <Modal show={show} onHide={modalClose} centered size="lg" backdrop="static" enforceFocus={false} >
            <Modal.Header closeButton className="d-flex justify-content-center">
                <Modal.Title className="w-100 text-center fw-semibold">
                    <h6>Quiz | Test Details</h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="add_course_popup p-3">
                    <div className="content">
                        <div className="user-details">

                            <>
                                <Form.Group className="input-box w-100" controlId="formSubjectName">
                                    <Form.Label className="details">Test name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="test name"
                                        name='name'
                                        {...register('name')}
                                        className={` ${errors.name ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors?.name?.message}</div>
                                </Form.Group>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Form.Group className="input-box w-100 duration-input" controlId="formTime">
                                        <Form.Label className="details">Duration</Form.Label>
                                        <Controller
                                            name="duration"
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <TimePicker
                                                    {...field}
                                                    views={['hours', 'minutes', 'seconds']}
                                                    format='HH:mm:ss'
                                                    ampm={false}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            error={!!fieldState.error}
                                                            helperText={fieldState?.error?.message}

                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                        <span style={{ fontSize: '.875em', color: '#ef4a00' }}>{errors?.duration?.message}</span>
                                    </Form.Group>

                                </LocalizationProvider>
                            </>






                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>

                <Button variant="secondary" onClick={modalClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit(Addquiz)}>
                    <span className="d-flex align-items-center">
                        <i className="iconify" data-icon="ooui:add"></i>Add
                    </span>
                </Button>




            </Modal.Footer>
        </Modal>
    )
}

export default AddQuizModal