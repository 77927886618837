import React, { useState } from 'react'
import { Modal, Form, Button, InputGroup } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import QuestionSchema from '../../schema/questionsSchema';
import { Icon } from '@iconify/react';
import CourseApis from '../../queries/course';
import dayjs from 'dayjs';
import { CSSTransition, TransitionGroup } from 'react-transition-group';


function QuestionModal({ show, closeModal, moduleId, quizDetails, getQuiz }) {
    console.log("===quizDetails=====", quizDetails)
    const { register, trigger, setError, formState: { errors }, handleSubmit, reset, control, watch, setValue } = useForm({
        resolver: yupResolver(QuestionSchema),
    });
    const [options, setoptions] = useState([])
    const formValues = watch()
    const addQuiz = CourseApis.AddQuestions()
    const handleQuizSubmit = async () => {
        let isValid
        let payload = {}
        let quizData = []
        if (options?.length > 0) {
            isValid = await trigger('question')
            const correctAnsPresent = options.some((ans) => ans.isCorrect == 1)
            if (!correctAnsPresent) {
                isValid = false
                setError('answers', { message: 'Please select correct answer' })
            } else {
                setError('answers', { message: '' })
                isValid = true
            }

        } else {
            isValid = await trigger()
        }
        if (isValid) {
            const questionAndanswers = { question: formValues.question, options }
            quizData.push(questionAndanswers)
            const time = dayjs(quizDetails?.duration)
            const hour = time.hour()
            const minute = time.minute()
            const second = time.second()
            const totalSeconds = (hour * 3600) + (minute * 60) + second;
            payload["name"] = quizDetails?.name
            payload["duration"] = totalSeconds
            payload["data"] = quizData
            payload["moduleId"] = moduleId
            const response = await addQuiz.mutateAsync(payload)
            if (response.status) {
                closeModal()
                reset()
                setoptions([])
                await getQuiz()
            }
        }
    }

    const addOptions = async () => {
        const isValid = await trigger()
        if (isValid) {
            const option = { value: formValues.option, isCorrect: 0 }
            setoptions([...options, option])

            setValue('option', '')
        }

    }
    console.log("==options====", options)
    const dltOption = (option) => {
        const newOptions = options.filter((obj) => obj.value != option)
        setoptions(newOptions)
    }
    const selectAnswer = (slctedAns) => {
        const updateOptions = options.map((item) => {
            if (item.value === slctedAns) {
                return {
                    ...item,
                    isCorrect: 1
                }
            } else {
                return {
                    ...item,
                    isCorrect: 0
                }
            }
        })
        setoptions(updateOptions)
        if (errors.answers) {
            setError('answers', '')
        }

    }
    return (
        <Modal show={show} onHide={closeModal} centered size="lg" backdrop="static" enforceFocus={false} >
            <Modal.Header closeButton className="d-flex justify-content-center">
                <Modal.Title className="w-100 text-center fw-semibold"><h6>Add Questions</h6></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="add_course_popup p-2">
                    <div className="content">
                        <div className="user-details">
                            <>
                                <Form.Group className="input-box w-100" controlId="formSubjectName">
                                    <Form.Label className="details"><span style={{ fontWeight: 'bold' }}>Question</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter question"
                                        name='question'
                                        {...register('question')}
                                        className={` ${errors.question ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors?.question?.message}</div>
                                </Form.Group>
                                <Form.Label className="details"><span style={{ fontWeight: 'bold' }}>Option</span></Form.Label>
                                <InputGroup className="mb-3">

                                    <Form.Control
                                        placeholder="Enter option"
                                        aria-label="option"
                                        aria-describedby="basic-addon2"
                                        {...register('option')}
                                        className={` ${errors.option ? 'is-invalid' : ''}`}
                                    />
                                    <Button variant="primary" id="button-addon2"
                                        style={{
                                            height: '40px',
                                            borderTopRightRadius: '5px',
                                            borderBottomRightRadius: '5px'
                                        }}
                                        onClick={addOptions}
                                    >
                                        Add Option
                                    </Button>
                                    <div className="invalid-feedback">{errors?.option?.message}</div>
                                </InputGroup>
                                {/* <div className='col-lg-10'>
                                    <Form.Group className="input-box w-100" controlId="formSubjectName">
                                        <Form.Label className="details">Option</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter option"
                                            name='option'
                                            {...register('option')}
                                            className={` ${errors.option ? 'is-invalid' : ''}`}
                                        />

                                        <div className="invalid-feedback">{errors?.option?.message}</div>

                                    </Form.Group>
                                </div>
                                <div className='col-lg-2'>
                                    <button className='option-add-btn' type='button' onClick={addOptions}> Add Option</button>
                                </div> */}


                            </>
                        </div>
                        {
                            options.length > 0 && <p><Icon icon="flat-color-icons:info" width="24" height="24" />{" "}
                                <span className='fw-bold'>Select the correct answer from these</span></p>
                        }

                        <div className='options-container'>
                            <TransitionGroup className="options-container">
                                {options.length > 0 &&
                                    options.map((item, index) => (
                                        <CSSTransition
                                            key={item.value} // Use a unique identifier
                                            timeout={300} // Match the duration in CSS
                                            classNames="option"
                                        >
                                            <div className="option-wrapper">
                                                <span
                                                    className="delete-icon"
                                                    onClick={() => dltOption(item.value)}
                                                >
                                                    <Icon icon="typcn:delete" width="24" height="24" />
                                                </span>
                                                <button
                                                    className={`option-btns ${item?.isCorrect === 1 ? 'selected' : ''
                                                        }`}
                                                    type="button"
                                                    onClick={() => selectAnswer(item.value)}
                                                >
                                                    {item?.value}
                                                </button>
                                            </div>
                                        </CSSTransition>
                                    ))}
                            </TransitionGroup>
                            {/* {
                                options.length > 0 &&
                                options.map((item, index) => {
                                    return (
                                        <div key={index} className='option-wrapper'>
                                            <span className='delete-icon' onClick={() => dltOption(item.value)}>
                                                <Icon icon="typcn:delete" width="24" height="24" />
                                            </span>
                                            <button className={`option-btns ${item?.isCorrect === 1 ? 'selected' : ''}`} type='button'
                                                onClick={() => selectAnswer(item.value)}>{item?.value}</button>
                                        </div>
                                    )
                                })
                            } */}


                        </div>
                        <div className='mt-1'>
                            <span style={{ color: '#f02e2e', fontSize: '14px' }}>{errors?.answers?.message}</span>
                        </div>

                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>

                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleQuizSubmit} disabled={addQuiz?.isPending}>
                    <span className="d-flex align-items-center">
                        <i className="iconify" data-icon="ooui:add"></i>Add
                    </span>
                </Button>




            </Modal.Footer>
        </Modal>
    )
}

export default QuestionModal