import { Icon } from '@iconify/react'
import React, { useEffect, useState } from 'react'
import RevenueReport from './RevenueReport'
import Tiles from './Tiles'
import StudentStatistics from './StudentStatistics'
import RecentPurchases from './RecentPurchases'
import Transactions from './Transactions'
import TopTutors from './TopTutors'
import TopStudents from './TopStudents'
import DashboardApis from '../../queries/dashboard'

export default function MainDashboard() {

    const [period, setperiod] = useState('month')
    const [transactionPeriod, setTransactionPeriod] = useState('current')
    const [revenuePeriod, setrevenuePeriod] = useState('year')
    const { data: tiles, isLoading: tilesApiLoading } = DashboardApis.GetTiles()
    const { data: dountChartData, isLoading: donutChartApiLoading } = DashboardApis.StudentStatics(period)
    const { data: recentPurchaseData, isLoading: recentPrchsLoading } = DashboardApis.RecentPurchase()
    const { data: topTutors, isLoading: tutorsApiLoading } = DashboardApis.Toptutors()
    const { data: topStudents,isLoading:topStudentApiLoading } = DashboardApis.TopStudents()
    const { data: transactionsData, isLoading: trnsApiLoading } = DashboardApis.Transactions(transactionPeriod)
    const { data: RevenueReportData, isLoading: revChartApiLoading } = DashboardApis.RevenueReport(revenuePeriod)

    return (
        <div class="dashboard-main-body" data-aos="fade-right">
            <div class="row gy-4">

                <div className="col-xxl-9">
                    <div className="card radius-8 border-0">
                        {/* <div className="loader"></div> */}
                        {/* <span></span> */}
                        <div className="row">

                            <RevenueReport
                                RevenueReportData={RevenueReportData?.data}
                                revenuePeriod={revenuePeriod}
                                setrevenuePeriod={setrevenuePeriod}
                                revChartApiLoading={revChartApiLoading}
                            />


                            <Tiles
                                tilesData={tiles?.data}
                                tilesApiLoading={tilesApiLoading}
                            />
                        </div>
                    </div>
                </div>

                <StudentStatistics
                    dountChartData={dountChartData?.data}
                    setperiod={setperiod}
                    period={period}
                    donutChartApiLoading={donutChartApiLoading}
                />


                <RecentPurchases
                    recentPurchaseData={recentPurchaseData?.data}
                    recentPrchsLoading={recentPrchsLoading}
                />
                <Transactions
                    transactionsData={transactionsData?.data}
                    setTransactionPeriod={setTransactionPeriod}
                    transactionPeriod={transactionPeriod}
                    trnsApiLoading={trnsApiLoading}
                />


                <TopTutors topTutorsData={topTutors?.data} tutorsApiLoading={tutorsApiLoading} />
                <TopStudents topStudents={topStudents?.data} topStudentApiLoading={topStudentApiLoading}/>
            </div>
        </div>

    )
}
