import * as yup from "yup"

const Profileschema = yup
    .object({
        firstName: yup.string().required().matches(/^\S*$/, "First name cannot contain spaces"),
        lastName: yup.string().optional(),
        mobile: yup.string().matches(/^[0-9]+$/, "Mobile number must contain only digits").optional().nullable(),
        email: yup.string().email('invalid email').required().matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid mail format"),
        about: yup.string().optional().nullable()
    })
    .required()
export default Profileschema