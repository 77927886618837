import api from "../../api/axios.instance"

export const getTiles = async () => {
    try {
        const response = await api.get('admin/dashboard-tiles')
        return response
    } catch (error) {
        return error
    }
}
export const getStudentStatics = async (period) => {
    try {
        const response = await api.get('admin/student-statistics', { params: { period } })
        return response
    } catch (error) {
        return error
    }
}
export const getRecentPurchase = async (start, length, name) => {
    try {
        const response = await api.get('common/recent-purchases', { params: { start, length, username: name } })
        return response
    } catch (error) {
        return error
    }
}
export const getTopTutors = async (limit) => {
    try {
        const response = await api.get('admin/top-tutors', { params: { limit } })
        return response
    } catch (error) {
        return error
    }
}
export const getTopStudents = async (limit) => {
    try {
        const response = await api.get('common/top-students', { params: { limit } })
        return response
    } catch (error) {
        return error
    }
}
export const getTransactions = async (type) => {
    try {
        const response = await api.get('admin/transactions', { params: { type } })
        return response
    } catch (error) {
        return error
    }
}
export const getRevenuereport = async (period) => {
    try {
        const response = await api.get('admin/revenue-report', { params: { period } })
        return response
    } catch (error) {
        return error
    }
}
export const getNotifications = async (limit) => {
    try {
        const response = await api.get('common/notifications', { params: { limit } })
        return response
    } catch (error) {
        return error
    }
}
export const readNotification = async (data) => {
    try {
        const response = await api.post('common/notifications', data)
        return response
    } catch (error) {
        return error
    }
}
export const getAdminDetails = async () => {
    try {
        const response = await api.get('common/profile-photo')
        return response
    } catch (error) {
        return error
    }
}
export const getMlmstatus = async () => {
    try {
        const response = await api.get('admin/mlm-status')
        return response
    } catch (error) {
        return error
    }
}