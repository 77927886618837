import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Icon } from '@iconify/react'
import dayjs from 'dayjs';
import QuestionModal from './QuestionModal';
import CourseApis from '../../queries/course';
import ListGroup from 'react-bootstrap/ListGroup';
import Pagination from '../../common/Pagination';
import noDataimg from '../../assets/images/No data-cuate.png'
import { convertTime } from '../../utils/formatTime';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
function Questions() {

    const { moduleId } = useParams()
    const { state } = useLocation()

    const [length, setlength] = useState(5)
    const [page, setpage] = useState(1)
    const [openQuestionModal, setopenQuestionModal] = useState(false)
    const [testDetails, setTestDetails] = useState({
        name: '',
        duration: null
    })
    const { data: quiz, refetch: getQuiz, isLoading } = CourseApis.GetQuiz(moduleId, page, length)
    const closeModal = () => {
        setopenQuestionModal(false)
    }
    let hour, minute, second
    if (state?.data) {
        const time = dayjs(state?.data?.duration)
        hour = time.hour()
        minute = time.minute()
        second = time.second()
    } else {
        const { hours, minutes, seconds } = convertTime(quiz?.data?.duration)
        hour = hours
        minute = minutes
        second = seconds
    }


    useEffect(() => {
        setTestDetails({
            ...testDetails,
            name: state?.data?.name ?? quiz?.data?.QuizName,
            duration: state?.data?.duration ?? quiz?.data?.duration
        })
    }, [state, quiz])

    return (
        <>
            <div className="dashboard-main-body">
                <div className="card mt-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-style">
                                <thead>
                                    <tr>
                                        <th>
                                            <span className="d-block text-sm fw-normal fs-6">Test Name</span>
                                            <input
                                                type="text"
                                                className="moduleSecInput"
                                                id="floatingInput"
                                                // defaultValue="Module name"
                                                style={{ textTransform: 'capitalize' }}
                                                value={testDetails?.name??'NA'}
                                            />
                                        </th>
                                        <th>
                                        </th>

                                        <th>
                                            <div className="float-end d-flex justify-content-end">
                                                <div>
                                                    <span className="d-inline-block text-sm fw-normal fs-6">Total Time</span>
                                                    <div className="icon-field">
                                                        <Icon className="icon" icon="carbon:time"></Icon>
                                                        <input
                                                            type="text"
                                                            className="moduleSecInput"
                                                            // defaultValue="hour"
                                                            value={`${hour} Hour ${minute} minute ${second} seconds`}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </th>
                                        <th>
                                            <div className="float-end d-flex justify-content-end">
                                                <div>
                                                    <span className="d-inline-block text-sm fw-normal fs-6">Add Quiz</span>
                                                    <div className="icon-field">
                                                        <button
                                                            onClick={() => setopenQuestionModal(true)}
                                                        >
                                                            <Icon icon="mdi:paper-add" width="24" height="24" />
                                                        </button>


                                                    </div>

                                                </div>
                                            </div>

                                        </th>

                                    </tr>
                                </thead>
                            </table>
                        </div>
                        {
                            quiz?.data?.data?.length === 0 &&
                            <div className='text-center'>
                                <img src={noDataimg} className='w-25' />
                                <p>No questions found :(</p>
                            </div>
                        }
                        <ListGroup as="ol" variant="flush">
                            {isLoading ? (
                                Array.from({ length: 5 }).map((_index) => (
                                    <ListGroup.Item as="li" key={_index}>
                                        <Skeleton width="80%" />
                                        <div className="options-wraper">
                                            <ol type="a" className="options-list">
                                                {Array.from({ length: 3 }).map((_, optIndex) => (
                                                    <li key={optIndex}>
                                                        <Skeleton width="50%" />
                                                    </li>
                                                ))}
                                            </ol>
                                        </div>
                                    </ListGroup.Item>
                                ))
                            ) : (
                                quiz?.data?.data?.map((item, index) => {
                                    const qstnNo = (page - 1) * length + index + 1;
                                    return (
                                        <ListGroup.Item as="li" key={index}>
                                            {qstnNo}. <span className="questions">{item.question}</span>
                                            <div className="options-wraper">
                                                <ol type="a" className="options-list">
                                                    {item?.options?.map((option, optIndex) => (
                                                        <li key={optIndex}>
                                                            {String.fromCharCode(97 + optIndex)}){' '}
                                                            <span
                                                                style={{
                                                                    color: option.isCorrect ? 'rgba(47, 213, 47, 0.9)' : '',
                                                                    fontWeight: option.isCorrect ? 'bold' : '',
                                                                }}
                                                            >
                                                                {option.option}
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ol>
                                            </div>
                                        </ListGroup.Item>
                                    );
                                })
                            )}
                        </ListGroup>

                    </div>
                </div>
                {
                    quiz?.data?.totalQuestions > 0 &&
                    <Pagination
                        totalEntries={quiz?.data?.totalQuestions}
                        entriesPerPage={length}
                        currentPage={page}
                        onPageChange={setpage}
                    />
                }

            </div>
            <QuestionModal
                show={openQuestionModal}
                closeModal={closeModal}
                moduleId={moduleId}
                quizDetails={testDetails}
                getQuiz={getQuiz}
            />
        </>


    )
}

export default Questions