import React from 'react'
import { Icon } from '@iconify/react'
import Pagination from '../../common/Pagination';
import noDataimg from '../../assets/images/No data-cuate.png'
import CourseApis from '../../queries/course';
import toast from 'react-hot-toast'
import { convertTime } from '../../utils/formatTime';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import TableSkeleton from '../../utils/Skeletons/TableSkeleton';
import Skeleton from 'react-loading-skeleton'
function CourseModules({
    setshowVideoPreviewModal,
    moduleData,
    setshowAddSubjectModal,
    page,
    setpage,
    length,
    setvideoUrl,
    getAllmodules,
    openSubAddmodal,
    editHandler,
    openEditModule,
    openQuizModal,
    moduleApiLoading
}) {

    const convertSecondsToTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
    const openVideoPreview = (vidUrl) => {
        setvideoUrl(vidUrl)
        setshowVideoPreviewModal(true)
    }
    const dltTask = CourseApis.DltTask()
    const dltTaskhandler = async (taskId) => {
        const response = await dltTask.mutateAsync({ taskId })
        if (response.status) {
            toast.success('Task deleted')
            await getAllmodules()
        } else {
            toast.error('something went wrong')
        }
    }


    return (
        <>
            {
                moduleApiLoading ?
                    <>
                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-style">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span className="d-block text-sm fw-normal fs-6">Module Name</span>
                                                    <input
                                                        type="text"
                                                        className="moduleSecInput"
                                                        id="floatingInput"
                                                        defaultValue="Module name"
                                                    />
                                                </th>
                                                <th>
                                                    <div className="float-end d-flex justify-content-end">
                                                        <div>
                                                            <span className="d-inline-block text-sm fw-normal fs-6">Total Time</span>
                                                            <div className="icon-field">
                                                                <Icon className="icon" icon="carbon:time"></Icon>
                                                                <input
                                                                    type="text"
                                                                    className="moduleSecInput"
                                                                    defaultValue="hour"

                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="float-end d-flex justify-content-end">
                                                        <div>
                                                            <span className="d-inline-block text-sm fw-normal fs-6">Edit</span>
                                                            <div className="icon-field">
                                                                <button>
                                                                    <Icon icon="ri:edit-2-fill" width="20" height="20" />
                                                                </button>


                                                            </div>

                                                        </div>
                                                    </div>

                                                </th>

                                            </tr>
                                        </thead>
                                    </table>
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-style">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Subject Name</th>
                                                <th>Duration</th>
                                                <th>URL</th>
                                                <th>Preview</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <TableSkeleton rowCount={4} colCount={6} />
                                    </table>
                                </div>

                            </div>
                        </div>


                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-style">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span className="d-block text-sm fw-normal fs-6">Module Description</span>

                                                    <p><Skeleton count={5} baseColor='#f5f6fa'/></p>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <div className='module-btns'>
                                                        <Skeleton width={100} height={30} baseColor='#f5f6fa'/>
                                                        
                                                        <Skeleton width={100} height={30} baseColor='#f5f6fa'/>

                                                    </div>

                                                </td>

                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </>
                    :

                    moduleData?.data?.modules &&
                        moduleData?.data?.modules?.length > 0 ?
                        <>
                            {moduleData?.data?.modules?.map((item, index) => {
                                const { hours, minutes, seconds } = convertTime(item?.duration)
                                return (
                                    <>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table table-style">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <span className="d-block text-sm fw-normal fs-6">Module Name</span>
                                                                    <input
                                                                        type="text"
                                                                        className="moduleSecInput"
                                                                        id="floatingInput"
                                                                        // defaultValue="Module name"
                                                                        value={item.name}
                                                                    />
                                                                </th>
                                                                <th>
                                                                    <div className="float-end d-flex justify-content-end">
                                                                        <div>
                                                                            <span className="d-inline-block text-sm fw-normal fs-6">Total Time</span>
                                                                            <div className="icon-field">
                                                                                <Icon className="icon" icon="carbon:time"></Icon>
                                                                                <input
                                                                                    type="text"
                                                                                    className="moduleSecInput"
                                                                                    // defaultValue="hour"
                                                                                    value={`${hours} Hour ${minutes} minute ${seconds} seconds`}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div className="float-end d-flex justify-content-end">
                                                                        <div>
                                                                            <span className="d-inline-block text-sm fw-normal fs-6">Edit</span>
                                                                            <div className="icon-field">
                                                                                <button onClick={() => openEditModule(item)}>
                                                                                    <Icon icon="ri:edit-2-fill" width="20" height="20" />
                                                                                </button>


                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </th>

                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>

                                                <div className="table-responsive">
                                                    <table className="table table-style">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Subject Name</th>
                                                                <th>Duration</th>
                                                                <th>URL</th>
                                                                <th>Preview</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="tbody-add-row">
                                                            {
                                                                item?.tasks.length > 0 ?
                                                                    item?.tasks?.map((task, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{index + 1}</td>
                                                                                <td>

                                                                                    <input
                                                                                        type="text"
                                                                                        className="moduleSecInput"
                                                                                        value={task?.title}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="moduleSecInput"
                                                                                        // defaultValue="8:58:29"
                                                                                        value={convertSecondsToTime(task?.duration)}
                                                                                        readOnly
                                                                                    />
                                                                                </td>

                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="moduleSecInput"
                                                                                        value={task?.url}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <button
                                                                                        type="button"
                                                                                        // data-bs-target="#viewVideo"
                                                                                        // data-bs-toggle="modal"
                                                                                        onClick={() => openVideoPreview(task?.url)}
                                                                                        className="bg-info-focus bg-hover-info-200 text-info-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                                                                                    >
                                                                                        <Icon icon="ph:video" className="icon text-xl"></Icon>
                                                                                    </button>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="d-flex">
                                                                                        <button className="btn btn-danger action_btn_edit_delete remove me-2"
                                                                                            onClick={() => dltTaskhandler(task.id)}>
                                                                                            <Icon icon="ic:baseline-delete" className="icon text-lg"></Icon>
                                                                                        </button>
                                                                                        <button className="btn btn-info action_btn_edit_delete remove"
                                                                                            onClick={() => editHandler(task, item?.id)}>
                                                                                            <Icon icon="cuida:edit-outline" className="icon text-lg" />
                                                                                        </button>
                                                                                    </div>

                                                                                </td>

                                                                            </tr>
                                                                        )
                                                                    })
                                                                    :
                                                                    <tr>
                                                                        <td colSpan={6} style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                            <img src={noDataimg} className="w-25" />

                                                                        </td>
                                                                    </tr>


                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table table-style">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <span className="d-block text-sm fw-normal fs-6">Module Description</span>
                                                                    {/* <input
                                                                    type="text"
                                                                    className="moduleSecInput"
                                                                    id="floatingInput"
                                                                    value={item.description}
                                                                /> */}
                                                                    <p>{item.description}</p>
                                                                </th>
                                                                {/* <th>
                                                                <div className="float-end d-flex justify-content-end">
                                                                    <div>
                                                                        <span className="d-inline-block text-sm fw-normal fs-6">Total Time</span>
                                                                        <div className="icon-field">
                                                                            <Icon className="icon" icon="carbon:time"></Icon>
                                                                            <input
                                                                                type="text"
                                                                                className="moduleSecInput"
                                                                                value={`${hours} Hour ${minutes} minute ${seconds} seconds`}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </th> */}
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <div className='module-btns'>
                                                                        <button
                                                                            className="btn btn-outline-primary-600 radius-8 px-20 py-11"
                                                                            // data-bs-toggle="modal"
                                                                            // data-bs-target="#staticBackdrop-add-subject"
                                                                            onClick={() => openSubAddmodal(item.id)}
                                                                        >
                                                                            Add Subject
                                                                        </button>
                                                                        {
                                                                            item?.quizStatus ?
                                                                                <button
                                                                                    className="btn btn-outline-primary-600 radius-8 px-20 py-11"
                                                                                    // data-bs-toggle="modal"
                                                                                    // data-bs-target="#staticBackdrop-add-subject"
                                                                                    disabled={item?.tasks?.length == 0 ? true : false}

                                                                                    onClick={() => openQuizModal(item.id)}
                                                                                >
                                                                                    Add Quiz
                                                                                </button>
                                                                                :
                                                                                <Link to={`/add-questions/${item.id}`}>
                                                                                    <button
                                                                                        className="btn btn-outline-primary-600 radius-8 px-20 py-11"
                                                                                    // data-bs-toggle="modal"
                                                                                    // data-bs-target="#staticBackdrop-add-subject"

                                                                                    >
                                                                                        Show Quiz
                                                                                    </button>
                                                                                </Link>
                                                                        }

                                                                    </div>

                                                                </td>

                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </>

                                )
                            })}
                            <Pagination
                                totalEntries={moduleData?.data?.totalCount}
                                entriesPerPage={length}
                                currentPage={page}
                                onPageChange={setpage}

                            />
                        </>
                        :
                        <div className="card mt-4">
                            <div className="card-body d-flex justify-content-center">
                                <img src={noDataimg} className="w-50" />
                            </div>
                        </div>


            }

        </>

    )
}

export default CourseModules