import * as yup from "yup"

const TutorEditSchema = yup
    .object({
        firstName: yup.string().required().matches(/^\S*$/, "First name cannot contain spaces"),
        lastName:yup.string().optional().matches(/^\S*$/, "Last name cannot contain spaces"),
        mobile: yup.string().matches(/^[0-9]+$/, "Mobile number must contain only digits").optional(),
        email: yup.string().email('invalid email').required().matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid mail format"),
        course: yup.array().optional(),   
        password:yup.string().optional(),        
       // designation: yup.string().required(),
        // language: yup.string().required(),
        about: yup.string().optional()
    })
    .required()

export default TutorEditSchema