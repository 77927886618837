import React, { useState } from 'react'
import Tiles from '../../components/sales-report/Tiles'
import SalesReportTable from '../../components/sales-report/SalesReportTable'
import TutorDashboardApis from '../../queries/tutor.dashboard'
import DashboardApis from '../../queries/dashboard'
import SalesReportApis from '../../queries/sales.report'
function SalesReport() {
    const [page, setpage] = useState(1)
    const [length, setlength] = useState(10)
    const [username, setusername] = useState('')
    const { data: purchaseDetails, isLoading } = DashboardApis.RecentPurchase(page, length, username)
    const { data: tilesData, isLoading: tilesApiLoading } = SalesReportApis.GetReportTiles()
    return (
        <div className="dashboard-main-body">
            <Tiles tilesData={tilesData} tilesApiLoading={tilesApiLoading} />
            <SalesReportTable
                page={page}
                setpage={setpage}
                length={length}
                salesReportdata={purchaseDetails?.data}
                setusername={setusername}
                isApifetching={isLoading}
            />
        </div>
    )
}

export default SalesReport