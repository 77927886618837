import React, { useState } from 'react'
import ListRankedStudents from '../../components/student/ListRankedStudents'
import StudentListTable from '../../components/student/StudentListTable'
import AddstudentModal from '../../components/student/AddstudentModal';
import DashboardApis from '../../queries/dashboard';
import StudentApis from '../../queries/student';
import EditStudentDetails from '../../components/student/EditStudentDetails';
import { useAuth } from '../../context/AuthContext';
import Skeleton from 'react-loading-skeleton'

function Student() {
    const [show, setShow] = useState(false);
    const [page, setpage] = useState(1)
    const [status, setstatus] = useState('')
    const [username, setusername] = useState('')
    const [editUserId, seteditUserId] = useState()
    const [length, setlength] = useState(10)
    const { data: studentTabledata, refetch: getAllstudents, isLoading } = StudentApis.GetallStudents(page, length, status, username)
    const [editModal, seteditModal] = useState(false)
    const handleClose = () => setShow(false);
    const closeEditModal = () => seteditModal(false)
    const { data: topTutorsData, isLoading: topStdntsApiLoading } = DashboardApis.TopStudents()
    const { userType } = useAuth()
    return (
        <>
            <div className='dashboard-main-body'>
                <ListRankedStudents
                    topTutorsData={topTutorsData?.data}
                    topStdntsApiLoading={topStdntsApiLoading} />

                <StudentListTable
                    setModalShow={setShow}
                    studentTabledata={studentTabledata?.data}
                    page={page}
                    setpage={setpage}
                    length={length}
                    status={status}
                    username={username}
                    setstatus={setstatus}
                    setusername={setusername}
                    seteditModal={seteditModal}
                    getAllstudents={getAllstudents}
                    seteditUserId={seteditUserId}
                    IsapiFetching={isLoading}
                    userType={userType}
                />


            </div>
            <AddstudentModal
                show={show}
                handleClose={handleClose}
                getAllstudents={getAllstudents}
            />

            <EditStudentDetails
                show={editModal}
                handleClose={closeEditModal}
                getAllstudents={getAllstudents}
                editUserId={editUserId} />
        </>

    )
}

export default Student